import { useEffect, useState } from 'react';
import './App.css';
import emailjs from 'emailjs-com'

function App() {
  const [email, setEmail] = useState('')
  const [buttonInActive, setButtonInActive] = useState(false)
  const [mobile, setMobile] = useState(false)
  let width = window.screen.width

  const emailIcon = <i className="fa-regular fa-envelope icon"></i>

  useEffect(() => {
    if (width <= 800) {
      setMobile(true)
    }
  }, [width])

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }
  console.log(email)

  function handleSubmitClick(event) {
    let data = {
      'email': email
    }
    if (email) {
        setButtonInActive(true)
        emailjs.send('default_service', 'template_szcmsrd', data, 'user_uQErrUaiwt7Py053u4ev0')
        .then(
          (response) => {
              setButtonInActive(false)
              window.alert('We have recieved your contact request and will get back to you shortly')
              setEmail('')
          },

          (err) => {
              console.log(err)
              setEmail('')
          }
        );
    }

    }

  return (
    <div className={mobile ? 'mobileLandingPage' : 'landingPage'}>
      <div className={mobile ? 'mobileLandingContainer' : 'landingContainer'}>
        <div className={mobile ? 'mobileLandingContent' : 'landingContent'}>
            <img src={`${process.env.PUBLIC_URL}/AveonIconText.png`} alt='textIcon'/>
            <div className='contentText'>
              <p>At <span>Aveon</span>, we believe in the power of software to help organizations reach their goals. 
              Our team of experienced software developers and engineers can empower your 
              organization to do more through software.</p>

              <p><span>Contact us to learn more about how we can help you reach your goals.</span></p>
            </div>
            <div className={mobile ? 'mobileInputContainer' : 'inputContainer'}>
              { !mobile &&
                emailIcon
              }
              <input className='landingInput' placeholder='Enter your email' onChange={handleEmailChange} value={email} />
              <button className={mobile ? 'mobileLandingButton' : 'landingButton'} type='submit' onClick={handleSubmitClick} disabled={buttonInActive}>{mobile ? emailIcon : "Work with us"}</button>
            </div>
            { mobile &&
              <div className='mobileFooter'>
                <img src={`${process.env.PUBLIC_URL}/mobileFooter.png`} alt='footer'/>
              </div>
            }
        </div>
        { !mobile &&
        <div className='landingIcon'>
          <img src={`${process.env.PUBLIC_URL}/AveonIcon.png`} alt='icon'/>
        </div>
        }
      </div>
    </div>
  );
}

export default App;
